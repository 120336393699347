<template>
  <div class="col-12">
    <q-card class="bg-white no-margin no-border">
      <q-card-section>
        <q-input
          ref="invoiceTemplateInput"
          v-model="content"
          :label="$t('custom_invoice_content_template')"
          autogrow
          class="q-pb-none"
        />
        <div class="text-caption q-mb-xs">
          {{ $t('available_invoice_template_variables') }}
        </div>
        <q-chip
          v-for="variable in variables"
          :key="variable.label"
          color="primary"
          text-color="white"
          clickable
          dense
          @click="appendVariable(variable)"
        >
          {{ variable.label }}
          <q-tooltip>{{ getValue(variable.label) }}: {{ variable.desc }}</q-tooltip>
        </q-chip>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  props: ['value'],
  data () {
    return {
      variables: [
        {
          label: this.$t('account'),
          desc: this.$t('organisation.account_number')
        },
        {
          label: this.$t('journey.name'),
          desc: this.$t('invoice.journey_name')
        },
        {
          label: this.$t('booking.name'),
          desc: this.$t('invoice.booking_name')
        },
        {
          label: this.$t('booking.information'),
          desc: this.$t('booking.relevant_information')
        },
        {
          label: this.$t('reason_for_travel'),
          desc: this.$t('invoice.travel_reason')
        },
        {
          label: this.$t('booking.reference'),
          desc: this.$t('tooltip.booking_reference')
        },
        {
          label: this.$t('date.travel'),
          desc: this.$t('tooltip.booking_date')
        },
        {
          label: this.$tc('address'),
          desc: this.$t('organisation.address')
        },
        {
          label: this.$t('organisation.name'),
          desc: this.$t('tooltip.member_organisation_name')
        },
        {
          label: this.$t('traveller_name'),
          desc: this.$t('invoice.traveller_name')
        },
        {
          label: this.$t('traveller_email'),
          desc: this.$t('invoice.traveller_email')
        },
        {
          label: this.$t('requester_name'),
          desc: this.$t('invoice.requester_name')
        },
        {
          label: this.$t('requester_email'),
          desc: this.$t('invoice.requester_email')
        }
      ]
    }
  },
  computed: {
    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    getValue: (label) => `[${String(label).toUpperCase().replace(' ', '_')}]`,
    appendVariable (variable = null) {
      if (variable) {
        this.$refs.invoiceTemplateInput.focus()
        var existing = this.content ? this.content : ''

        this.content = `${existing} ${this.getValue(variable.label)}`
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

.invoice-template-option-button
  cursor pointer

  &:hover
    color black
</style>
